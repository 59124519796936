import React from 'react';
// @ts-ignore
// import saga from '../../../assets/pictures/projects/software/saga.mp4';
// @ts-ignore
// import computer from '../../../assets/pictures/projects/software/computer.mp4';
// @ts-ignore
// import scroll from '../../../assets/pictures/projects/software/scroll.mp4';
import JumpShowcase from '../JumpShowcase';
import JumpFunction from '../JumpFunction';
// import VideoAsset from '../../general/VideoAsset';

export interface JumpProjectsProps {}

const JumpProjects: React.FC<JumpProjectsProps> = (props) => {
    return (
        <div className="site-page-content">
            <h1>MEME & JUMP</h1>
            <h3>Project</h3>
            <br />
            <p>
                Meme Jump is a dynamic and entertaining game designed to provide
                users with a stress-relieving and rewarding “play-to-earn”
                experience.
            </p>
            <br />
            <JumpShowcase />
            <br />
            <div className="text-block">
                <h2>Website - memejump.io</h2>
                <br />
                <p>
                    By combining interactive mini-games with a unique
                    advertising ecosystem, Meme Jump introduces a refreshing
                    approach to digital engagement on Web3. Our mission is to
                    create a platform that captivates users while also opening
                    the door to innovative opportunities within the
                    decentralized digital landscape.
                </p>
                <br />
                <div className="captioned-image">
                    {/* <VideoAsset src={computer} /> */}
                    <JumpFunction />
                    {/* <p style={styles.caption}>
                        <sub>
                            <b>Figure 1:</b> Blender Scene of the 3D website.
                            The scene from Blender was baked and exported in a
                            GLTF format.
                        </sub>
                    </p> */}
                </div>
                {/* <p>
                    Now, a quick technical breakdown of the site. The website is
                    split into two parts, the 3D site, and the 2D OS site. The
                    3D site uses Three.js to render the scene and renders the 2D
                    site inside of it using an iframe. The 2D OS site is a
                    simple react site that is hosted{' '}
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://henryheffernan.vercel.app/"
                    >
                        here
                    </a>{' '}
                    and works as a standalone web app. The actual rendering of
                    the 2D site is accomplished using a CSS renderer provided by
                    Three.js that transforms the html of the 2D site with 3D CSS
                    transforms to give the illusion of three dimensionality.
                </p> */}
                <p>
                    Through high-quality mini-games, Meme Jump offers an
                    immersive and enjoyable environment where users can earn
                    rewards, connect with others, and explore Web3 concepts in a
                    fun, accessible way. This approach transforms ordinary
                    gameplay into an engaging experience that fosters user
                    interaction and community building on Telegram. By
                    integrating play-to-earn elements, Meme Jump not only
                    provides entertainment but also enables users to benefit
                    from their engagement, reinforcing the value of their time
                    and activity on the platform. With its seamless integration
                    of social and financial incentives, Meme Jump serves as a
                    bridge between traditional gaming and the Web3 ecosystem,
                    empowering users to discover new possibilities in a
                    decentralized world.
                </p>
                <br />
                <h3>Links:</h3>
                <ul>
                    <li>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://t.me/memejump_bot/memejump?startapp=GZEMUAGT"
                        >
                            <p>
                                <b>[Telegram Bot]</b> - memejump_bot
                            </p>
                        </a>
                    </li>
                    <li>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://x.com/TONmemejump"
                        >
                            <p>
                                <b>[X]</b> - x.com/TONmemejump
                            </p>
                        </a>
                    </li>
                    <li>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://github.com/henryjeff/portfolio-website"
                        >
                            <p>
                                <b>[Website]</b> - Meme Jump Website
                            </p>
                        </a>
                    </li>
                </ul>
                {/* <p>
                    I'm skipping over a lot of details in exchange for brevity,
                    but I do plan on doing a more in depth breakdown for those
                    interested sometime in the future. To get updates with that
                    project feel free to follow me on twitter{' '}
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://twitter.com/henryheffernan"
                    >
                        @henryheffernan
                    </a>
                </p> */}
            </div>
            {/* <div className="text-block">
                <h2>Guntattchment Saga The Sequel</h2>
                <br />
                <p>
                    Guntattchment Saga or GSTS is a game I made with my good
                    friend Scott Bass back in 2019. The game is a wave based top
                    down shooter where you progress by building the most
                    outrageous of weapons through attaching as many attachments
                    on your weapon as you can. The game ceased development in
                    2019 because we unfortunately both had packed school
                    schedules and were unable to find the time to work on it.
                </p>
                <br />
                <div className="captioned-image">
                    <VideoAsset src={saga} />
                    <div style={styles.caption}>
                        <p>
                            <sub>
                                <b>Figure 2: </b> Guntattchment Saga Gameplay
                                demo, featuring the main character along with a
                                few of our grunt and dasher enemies.
                            </sub>
                        </p>
                    </div>
                </div>
                <p>
                    Guntattchment Saga was a really fun project to work on and
                    had a multitude of challenges. An interesting challenge that
                    you may not even notice was the rotation of objects such as
                    the players weapon. Rotating pixel art is a very difficult
                    thing to do because in the world of pixels you are dealing
                    with such a little amount of information. Even a single
                    misplaced pixel can cause a sprite to read in a totally
                    different and unintended way. To deal with this we developed
                    an algorithm to prioritize outlines, while rotating the
                    insides with a simple Nearest Neighbor algorithm. Doing so
                    resulted in a relatively readable sprite when rotating
                    sprites. It was a surprisingly difficult challenge as it
                    intersected both art and programming, but was fun to work on
                    overall.
                </p>
                <br />
                <h3>Links:</h3>
                <ul>
                    <li>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://github.com/scottbass47/gsts"
                        >
                            <p>
                                <b>[GitHub]</b> - Guntattchment Saga The Sequel
                                Unity Repository
                            </p>
                        </a>
                    </li>
                    <li>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://github.com/henryjeff/gsts_assets"
                        >
                            <p>
                                <b>[GitHub]</b> - Guntattchment Saga The Sequel
                                Assets Repository
                            </p>
                        </a>
                    </li>
                </ul>
                <p>
                    Unfortunately, the game currently is not in a releasable
                    state and so there is no demo available online. If this ever
                    changes, however, a link to the game will be added here.
                </p>
            </div> */}
            {/* <div className="text-block">
                <h2>Skip the Scroll</h2>
                <br />
                <p>
                    While working at Hover, I spent a lot of time on GitHub
                    issue pages looking for answers and solutions to problems I
                    had run into. I would always find myself sifting though the
                    comments trying to find the right answer, which usually had
                    the most upvotes and positive reactions. With that
                    information, I decided to create a very simple chrome
                    extension that would sift through all the comments on the
                    page, sort them by positive reactions, and then allow you to
                    traverse them from most positive reactions to least positive
                    reactions.
                </p>
                <br />
                <div className="captioned-image">
                    <VideoAsset src={scroll} />
                    <p style={styles.caption}>
                        <sub>
                            <b>Figure 3:</b> Skip the Scroll in action, finding
                            the highest rated comments and scrolling right to
                            them
                        </sub>
                    </p>
                </div>
                <p>
                    The extension is open source and currently released on the
                    Chrome web store. Skip the Scroll is obviously not a project
                    with massive scope, but was fun to make and dive into the
                    world of browser extensions. I wanted to showcase since it's
                    a developer tool and I wanna give it some visibility for
                    those who might find it useful.
                </p>
                <br />
                <h3>Links:</h3>
                <ul>
                    <li>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://github.com/henryjeff/skip-the-scroll"
                        >
                            <p>
                                <b>[GitHub]</b> - Skip the Scroll Repository
                            </p>
                        </a>
                    </li>
                    <li>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://chrome.google.com/webstore/detail/skip-the-scroll/mfehannpjmgfagldoilpngeoecdfgmnd"
                        >
                            <p>
                                <b>[Chrome Web Store]</b> - Skip the Scroll
                            </p>
                        </a>
                    </li>
                </ul>
                <p>
                    If you are a developer and have also found yourself
                    scrolling through github comment after github comment saying
                    "i also have this problem...", then I highly recommend you
                    check out Skip the Scroll to save you some of your precious
                    time. If you like it, feel free to star it on GitHub and
                    rate it on the Chrome web store.
                </p>
            </div> */}
            {/* <ResumeDownload /> */}
        </div>
    );
};

// const styles: StyleSheetCSS = {
//     video: {
//         width: '100%',
//         padding: 12,
//     },
//     caption: {
//         width: '80%',
//     },
// };

export default JumpProjects;
